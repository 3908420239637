import * as React from "react";
import type { SVGProps } from "react";
export const SvgTftLogoWhite = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 30 30" {...props}>
    <g filter="url(#tft-logo-white_svg__a)">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="m12.069 18.693.03-10.848H5.802L3.366 3h23.268l-2.415 4.848h-6.258l-.03 13.482c-1.527-.279-3.558-.987-5.862-2.637M23.67 21.15s.006-3.186-3.33-6.252v-.003c3.657 2.292 5.316 6.324 5.316 6.324s2.013 6.036-5.148 5.772-11.784-4.839-11.784-4.839c3.618 2.802 7.965 2.832 7.965 2.832s-7.233-2.316-10.887-8.178c-3.264-5.784 3.234-6.222 3.234-6.222s-6.321 1.581.168 7.593c6.492 6.012 11.784 4.743 11.784 4.743s3.366-.549-.12-5.1c0 0 1.557.648 2.802 3.33"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <filter
        id="tft-logo-white_svg__a"
        width={23.268}
        height={25.999}
        x={3.366}
        y={3}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.027451 0 0 0 0 0.0313726 0 0 0 0 0.121569 0 0 0 0.37 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_2619_9882" />
        <feBlend in="SourceGraphic" in2="effect1_dropShadow_2619_9882" result="shape" />
      </filter>
    </defs>
  </svg>
);
