import { SvgTftLogoWhite } from "@ugitgud/legos/ui/icon/SVG/TftLogoWhite";
import { ReactComponent as RightArrow } from "svg/right-arrow-head.svg";

const TockersPromo2 = () => {
  return (
    <a href="https://u.gg/tft/tockers-trials/comps?utm_source=ugghometopbanner" className="tockers-promo-container">
      <div className="promo-content">
        <SvgTftLogoWhite className="tockers-logo" />
        <div className="first-section">
          <div className="first-row">
            <div className="new-tag">NEW</div>
            <span className="top-text">TEST YOURSELF IN TOCKER'S TRIALS</span>
          </div>
          <span className="bottom-text">Builds and tier lists for TFT’s new PvE mode</span>
        </div>
        <div className="cta-button">
          <span>Check out Trials</span> <RightArrow className="arrow-icon" />
        </div>
      </div>
    </a>
  );
};

export default TockersPromo2;
